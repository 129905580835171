.testimonials {
    display: flex;
    flex-flow: wrap;
}

.agende {
    background: linear-gradient(to right, #120E0E 0%, #231F20 100%);
}

.agende-section {
    margin-top: 300px;
   

    @media (max-width: 768px) {
        margin-top: 70px;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    
}

.btn-agende{
    padding: 16px 32px;
    color: #fff;
    background-color: #EE1D23;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 32px;
    text-transform: uppercase;

   
        width: fit-content !important ;
        align-self: center !important;
    
}

.agende-p{
    margin-top: -16px;
}